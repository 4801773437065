import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Course = ({
  name,
  price,
  students,
  lessons,
  image,
  id,
  duration,
  regstatus,
  status,
  type
  
}) => {
  const navigate = useNavigate();
  const imageClicked = (id, price, status) => {
    if (status != "coming_soon") {
      navigate(`/coursedetails/${id}`);
      localStorage.setItem("price", price);
      localStorage.setItem("students", students);
      localStorage.setItem("lessons", lessons);
    }
  };
  return (
    <div>
      <div className="col max-mb-30" data-aos="fade-up">
        <div className="course">
          <div className="thumbnail">
            {/* <Link to={`/coursedetails/${id}`} className="image"> */}

            <img
              // src="assets/images/courses/370/course-1.jpg"
              src={image}
              onClick={() => imageClicked(id, price, status)}
              alt="Coursemage"
              className="c-s"
              style={{ cursor: "pointer" }}
            />
            {/* </Link> */}
          </div>
          <div className="info regi-main">
            {/* //for other country */}
            {(regstatus == "yes" && status != "coming_soon") ? (
              <span className="regi-btn">Registered</span>
            ) : (
              ""
            )}
            {status === "coming_soon" ? (
              <span className="regi-btn c-s-c">Coming soon</span>
            ) : (
              ""
            )}

            <span className="price title-tra">
              {/* $40<span>.00</span> */}
              <a
                //to={`/coursedetails/${id}`}
                className="image"
                onClick={() => imageClicked(id, price, status)}
              >
                {name}
              </a>
            </span>
            <h6 className="cour-lab">
              {" "}
              {type !== null ? type != "" && "( " + type + " )" : null}{" "}
            </h6>
            {price ? (
              <h3 className="title">
                {price} / {id == 64397396 ? 'person' : duration}{' '}
              </h3>
            ) : (
              <>
                {/* <div className="c-content"> */}
                {/* <div>Check the price</div> */}
                <div className="c-values">
                  <div className="price-link"
                    onClick={() => navigate("/login")}
                    role="presentation"
                    style={{ color: "blue" }}
                  >
                    Sign in for price details
                  </div>
                </div>
                {/* </div> */}
              </>
            )}

            {/* <ul className="meta title-tra">
              <li>
                <i className="far fa-file-alt" />
                {lessons} Lessons
              </li>
              <li>
                <i className="far fa-clock" />
                {duration}
              </li>
            </ul> */}
          </div>
        </div >
      </div >
    </div >
  );
};

      export default Course;
